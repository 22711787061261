import React from 'react';
import { Card, Alert } from 'antd';
import '../../pages/user/Tutorial/index.css';

function Contraband() {
  return (
    <div className="Tutorial">
      <Alert
        showIcon
        type="warning"
        message="有疑问的话可以微信咨询你的操作员。仓库打包时若发现有违禁品，会移出订单并备注"
        style={{ marginBottom: '1rem' }}
      />

      <Card bordered={false} title="海运普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            最新规定：带弱电弱磁的货物也可以寄了，纯电池不行。非国外品牌服装、鞋子、包包、饰品，中文书（非宗教信仰、政治类），不带电玩具（如电池可拆卸则可以拆出后运），小件床上用品（四件套等），部分家居类（如窗帘，小的收纳盒，浴巾、毛巾、地毯），烘焙模具，厨房工具（厨房神器、锅具等），餐具类刀（沙拉刀等），早教机、有声书（拆电池），玻璃制品（易碎类的需要包装好），少量陶瓷（一两件），爬行垫（注意尺寸限制），电子手表（小电池）等等
          </p>
          <p>
            尺寸要求：最长边 {'<='} 1m，次长边 {'<='} 70cm，两个短边之和 &times; 2 + 最长边 {'<='}{' '}
            3m，单件限重 22kg，超出尺寸/不规则形状要额外收取费用，另询客服
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            所有防疫用品（口罩、防护服等，不论是不是医用），液体（极少量也算），药品，粉末，香烟，烟丝，膏体，电池(干电池，锂电池/可充电电池等)，磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴），酒精，颜料，涂料，腐蚀性，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），原木制品，文物，钱币，刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），食品，化妆品，磁性玩具，名牌，A货，英文书籍，部分家居类（大件床上用品及所有家具），蜡烛制品，太阳能产品（不论是太阳能板还是带太阳能功能），一次性用品（纸袋，塑料袋，垃圾袋，购物袋），大量陶瓷制品（包括餐具），眼镜，汽车、摩托车用品（凡是与汽车、摩托车相关联的任何产品，车载手机支架可以），铅笔，中性笔，水彩笔，水彩/粉颜料，吸管，洗牙器，牙粉，牙线，螺丝，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池），小米扫地机器人，昂贵饰品。该渠道为包税线路，如有禁运品被查到需要征收高额关税以及罚款。
          </p>
        </div>
      </Card>
      <Card bordered={false} title="海运食品 / SAL 海运食品" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            在尺寸范围内，直邮的话，按照首重+续重计费，不收取体积费用。拼邮的话，若整箱重量超过
            21kg，也不收取体积费用（相当于大家分摊了首重，为了简单计费，就直接按每公斤来计费）；若拼邮包裹中有较多的抛货时，整个箱子到达尺寸上限后，重量仍小于
            21kg，会根据具体情况收取部分人的体积费用（买了抛货的人将按比例分摊首重）
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            该渠道要求最为宽松，大部分海运普货禁运的东西（除去以下禁运的）都可以考虑走该渠道。非液体、非白色粉状（白色粉状如果有正规包装的也可以，如乳酸菌粉等）、非膏状物、保质期长的食品（6
            个月以上），方便面，带包装的粉末类调味料，辣椒面、抹茶粉等，足贴，片状面膜，眼影，口红，唇膏，散粉等化妆品，非医用口罩等。隐形眼镜、指甲油这种少量液体的拼邮风险大，需要的话可以选择直邮(冲关试试，有被退回风险，要收退货费)
          </p>
          <p>
            尺寸要求：最长边 {'<='} 1m，三边之和 {'<='} 1.8m，单件限重 30kg
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带电池的（干电池，锂电池/可充电电池等，除非电池非常小），液体，白色粉状，膏状，油脂、燕窝、肉制品和药品（有被海关扣押的风险，被扣后不影响箱子里其他物品，但会影响时效），含液体膏状物的化妆品（乳液，洗面奶，洁面啫喱，卸妆油，膏状面膜泥，指甲油，粉底液等）
          </p>
        </div>
      </Card>

      <Card bordered={false} title="DHL / UPS / 顺丰 空运普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>
          <h4 style={{ color: '#2c662d' }}>可运</h4>

          <p>
            日常用品：服装、图书、玩具、家居、饰品、家用电器（不带电池、马达、喇叭，非磁性）等
            <br />
            品牌产品：仅限国内品牌的服装、鞋子、包包，国外品牌和带国外卡通形象的商品一律不行
            <br />
            部分化妆品：化妆笔、毛刷，另有专门的非液体类化妆品专线请咨询客服
            <br />
            非医用防疫物资：有专线可以走请咨询客服
          </p>
          <p>
            顺丰尺寸要求：最长边 {'<='} 2m，次长边 {'<='} 80cm，最短边 {'<='} 70cm，单件限重
            30kg，如果超出尺寸要额外增加费用，另询客服
          </p>
          <p>
            UPS/DHL 尺寸要求：最长边 {'<='} 1.2m，三边之和 {'<='} 2m，单件限重
            30kg，如果超出尺寸要额外增加费用，另询客服
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            空运要求更为严格，所有海运普货禁运的，空运普货都禁运。除此之外还包括：所有国外品牌和带国外卡通形象的商品，带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），不适合邮递条件的物品，包括液体（如酒、化妆水等）、乳霜类化妆品（乳霜、眼霜、睫毛膏、牙膏等）、颗粒、粉末、食品、茶叶、中药、西药、香烟、带电池的（干电池，锂电池/可充电电池等）、充电宝、磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴）、有攻击性的用具（如菜刀、剪刀）等，以及A货，金项链，玉器等贵重饰品，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池）
          </p>
        </div>
      </Card>
      <Card bordered={false} title="DHL 空运食品" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>
          <h4 style={{ color: '#2c662d' }}>可运</h4>

          <p>
            有正规包装的干货，如茶叶、辣条、干果、干的菌菇类、干木耳、干豆角、干花椒、海鲜干货、干人参等
          </p>
          <p>
            尺寸要求：最长边 {'<='} 1.2m，三边之和 {'<='} 2m，单件限重
            30kg，如果超出尺寸要额外增加费用，另询客服
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），带酱料包的方便面，任何形式的肉类（鸡、鸭、猪、牛、羊等）、燕窝、鸡蛋类等。任何颜色粉末颗粒、白色食品、新鲜肉类、新鲜果蔬、冷冻食品、自热火锅、各种带油带水调料（如老干妈、火锅底料、辣椒酱等）、蜂蜜（含液体蜂蜜）、液体（麻油、醋、酱油等）、阿胶、大米、烟、酒、中药、艾叶、西药、减肥药等
          </p>
        </div>
      </Card>
      <Card bordered={false} title="空运电池渠道" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>
          <h4 style={{ color: '#2c662d' }}>可运</h4>

          <p>可接内置电池、配套电池货，要求电量不超过100WH。首重0.5kg ￥180，续重￥60/0.5kg</p>
          <p>尺寸要求：请咨询客服</p>
        </div>
      </Card>
    </div>
  );
}

export default Contraband;
